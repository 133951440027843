
import { defaultValues } from "./Values.jsx";
import { ApolloClient, gql, InMemoryCache } from '@apollo/client';


const client = new ApolloClient({
    uri: '/data-api/graphql',
    cache: new InMemoryCache()
});

export const getInitialFilter = (query, param, defaultValue) => {
    if (Array.isArray(defaultValue)) {
        const value = query.getAll(param)
        if (defaultValue.length > 0 && value.length === 0) {
            return defaultValue
        }
        return value || defaultValue;
    }

    const value = query.get(param);
    return value || defaultValue;
};



export const getQueryParams = (filters) => {
    const params = new URLSearchParams();
    for (const key in filters) {
        console.log()
        if (key === 'coordinates' || key === 'cityCoordinates' || key === 'mapCenter') {
            continue;
        }
        if (Array.isArray(filters[key])) {
            filters[key].forEach(item => {
                params.append(key, item);
            });
        } else if (filters[key] !== defaultValues[key]) {
            params.set(key, filters[key]);
        }
    }
    return params
}

export const fetchById = async (id, fields) => {
    try {
        const requestGQL = gql`
        query Article_by_pk {
            article_by_pk(id: "${id}", _partitionKeyValue: "${id[0]}" ) {
                ${fields}
            }
        }
        `;
        const res = await client.query({ query: requestGQL })
        return res.data["article_by_pk"]
    } catch (error) {
        console.error("Error fetching data:", error);
        return []
    }
};


export const fetchFiltered = async (filters) => {
    try {
        const filtersGQL = getFiltersGQL(filters);
        console.log(filtersGQL)
        const requestGQL = gql`
        query Articles {
            articles ${filtersGQL} {
            items {
                id address priceUsd priceByn rooms lastUp avatar isOwner latitude longitude city
            }
        }
        }
        `;
        console.log(requestGQL)
        const res = await client.query({ query: requestGQL })
        return res;
    } catch (error) {
        console.error("Error fetching data:", error);
        return []
    }
};

export const timeDifference = (isoString) => {
    const now = new Date();
    const then = new Date(isoString);
    const diffInSeconds = Math.floor((now - then) / 1000);

    const getText = (value, singular, few, many) => {
        if (value % 10 === 1 && value % 100 !== 11) return `${value} ${singular}`;
        if (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 > 20)) return `${value} ${few}`;
        return `${value} ${many}`;
    };

    if (diffInSeconds < 60) {
        return getText(diffInSeconds, 'секунда', 'секунды', 'секунд');
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
        return getText(diffInMinutes, 'минута', 'минуты', 'минут');
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
        return getText(diffInHours, 'час', 'часа', 'часов');
    }

    const diffInDays = Math.floor(diffInHours / 24);
    return getText(diffInDays, 'день', 'дня', 'дней');

};


export const getFiltersGQL = (filters) => {
    let filterConditions = [];

    if (filters.rooms && filters.rooms.length > 0) {
        const rooms = filters.rooms.flatMap(item => {
            if (item === '4+') { 
                return [4, 5, 6, 7, 8];
            } 
            else if (item === 'ком.') { return [0] } 
            else { return [item];}
        })
        .map(Number)
        .reduce((acc, num) => acc + 2 ** num, 0);
        filterConditions.push(`rooms: ${rooms}`);
    }

    if (filters.minPriceUsd) {
        filterConditions.push(`priceMin: ${filters.minPriceUsd}`);
    }

    if (filters.maxPriceUsd) {
        filterConditions.push(`priceMax: ${filters.maxPriceUsd}`);
    }

    if (filters.isOwner) {
        filterConditions.push(`isOwner: true`);
    }
    if (filters.coordinates) {
        filterConditions.push(`longitudeMin: ${filters.coordinates[1]}`);
        filterConditions.push(`longitudeMax: ${filters.coordinates[3]}`);
        filterConditions.push(`latitudeMin: ${filters.coordinates[2]}`);
        filterConditions.push(`latitudeMax: ${filters.coordinates[0]}`);
    }
    return filterConditions.length > 0 ? `(${filterConditions.join(', ')})` : '';
};